import { Inject, Injectable, OnDestroy, OnInit } from "@angular/core";
import { MsalBroadcastService, MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from "@azure/msal-angular";
import { EventMessage, EventType, InteractionStatus } from "@azure/msal-browser";
import { filter, Subject, takeUntil } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable()
export class MsalAuthWrapper implements OnInit, OnDestroy {
  loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();

  constructor(
    private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
  ){}

  ngOnInit(): void {
    this.msalService.instance.enableAccountStorageEvents();
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.ACCOUNT_ADDED || msg.eventType === EventType.ACCOUNT_REMOVED),
      )
      .subscribe((result: EventMessage) => {
        if (this.msalService.instance.getAllAccounts().length === 0) {
          window.location.pathname = "/";
        } else {
          this.setLoginDisplay();
        }
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.setLoginDisplay();
      });
  }

  public login(callback: () => void){
    this.msalService.instance.handleRedirectPromise().then(authResult =>{
      const account = this.msalService.instance.getActiveAccount();
      if(!account){
        this.msalService.instance.loginPopup().then(res => {
          this.msalService.instance.setActiveAccount(res.account);
          callback();
        });
      }else{
        callback();
      }
    }).catch(err=>{
      // TODO: Handle errors
      console.log(err);
    });
  }

  public logout(){
    this.msalService.instance.handleRedirectPromise().then(authResult=>{
      this.msalService.instance.logoutRedirect({ postLogoutRedirectUri: environment.postLogoutRedirectUri });
    }).catch(err=>{
      // TODO: Handle errors
      console.log(err);
    });
  }

  private setLoginDisplay() {
    this.loginDisplay = this.msalService.instance.getAllAccounts().length > 0;
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
