import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { AuthenticationRoutingModule } from 'src/app/components/authentication/authentication-routing.module';
import { AuthService } from './auth.service';
import { TokenHttpInterceptor } from 'src/app/shared/http-interceptor/token.httpinterceptor';

import { LoginComponent } from './login.component';
import { MaterialModule } from 'src/app/shared/material-modules/material.module';
import { TranslateModule } from '@ngx-translate/core';
import { MatMenuModule } from '@angular/material/menu';
//import { LanguageSite } from 'src/app/shared/language-site/language-site';

//import { CarouselModule } from 'ngx-owl-carousel-o';


@NgModule({
  declarations: [
    LoginComponent
  ],
  imports: [
    CommonModule,
    AuthenticationRoutingModule,
    FormsModule,
    MaterialModule,
    MatMenuModule,
    TranslateModule.forChild(),
    // CarouselModule,
  ],
  providers: [
    AuthService,
    TokenHttpInterceptor,
//    LanguageSite
  ]
})
export class AuthenticationModule { }
