import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

const loginEndpoint = environment.endpoints.loginEndpoint;

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient) { }

  login(user: string, password: string): Observable<any> {
    var basicAuth = user + ":" + password;
    const httpOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': `Basic ` + btoa(basicAuth),
        }
      )
    };
    return this.http.post(loginEndpoint + 'Access', {}, httpOptions);
  }

  getMenu(): Observable<any>{
    return this.http.get(loginEndpoint + 'menus', httpOptions);
  }

  postAccess(payload: any):Observable<any>{
    return this.http.post(loginEndpoint + 'Access', payload, httpOptions);
  }
}
