import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { PopoverService } from '../../../components/popover/popover.service';
import { ToolbarUserDropdownComponent } from './toolbar-user-dropdown/toolbar-user-dropdown.component';
import { environment } from 'src/environments/environment';
import { JwtHelperService } from "@auth0/angular-jwt";
import { Router } from '@angular/router';
import { TokenStorageService } from 'src/app/shared/token-storage/token-storage.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { CnhiMenuMapperService } from 'src/app/shared/services/menu-mapper/cnhi-menu-mapper.service';
import { BrandConfigurationResolver } from 'src/app/shared/brands-configuration/brand-configuration-resolver';
//import { LanguageSite } from 'src/app/shared/language-site/language-site';
import { MsalAuthWrapper } from 'src/app/shared/wrappers/msal-auth-wrapper';
import { MsalService } from '@azure/msal-angular';


@Component({
  selector: 'vex-toolbar-user',
  templateUrl: './toolbar-user.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolbarUserComponent implements OnInit {
  token: any;
  jwtHelper: any;
  logomarca: string = this.brandConfiguration.getLogo();
  username: string;
  userId: string;
  dropdownOpen: boolean;

//  private languageSite: LanguageSite;
  menuList: any[];
  constructor(private popover: PopoverService,
              private cd: ChangeDetectorRef,
              private cnhiMenuMapperService: CnhiMenuMapperService,
              private brandConfiguration: BrandConfigurationResolver,
              private msalAuthWrapper: MsalAuthWrapper,
              private msalService: MsalService,
              public translate: TranslateService,
              private tokenStorage: TokenStorageService,
              )
  {
//    this.languageSite = new LanguageSite(this.translate);

    this.jwtHelper = new JwtHelperService();
    this.token = JSON.parse(this.jwtHelper.decodeToken(this.tokenStorage.getToken()).userData);

    this.username = this.token.Name
    this.userId = this.token.User
    this.menuList = this.tokenStorage.getMenu();

  }


  ngOnInit() {
  }

  showPopover(originRef: HTMLElement) {
    this.dropdownOpen = true;
    this.cd.markForCheck();

    const popoverRef = this.popover.open({
      content: ToolbarUserDropdownComponent,
      origin: originRef,
      offsetY: 12,
      position: [
        {
          originX: 'center',
          originY: 'top',
          overlayX: 'center',
          overlayY: 'bottom'
        },
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'top',
        },
      ]
    });

    popoverRef.afterClosed$.subscribe(() => {
      this.dropdownOpen = false;
      this.cd.markForCheck();
    });
  }
}
