import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { QueryStringFactory } from 'src/app/shared/query-string/query-string-factory';

const endpoint = environment.endpoints.apiEndpoint;

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private http: HttpClient,
    private queryStringFactory : QueryStringFactory
  ) { }

  addUser(payload:any): Observable<any> {
    return this.http.post(endpoint + 'user', payload, httpOptions);
  }

  activateUser(id:any): Observable<any> {
    return this.http.put(endpoint + 'user/Activate/' + id, httpOptions);
  }

  deactivateUser(id:any): Observable<any> {
    return this.http.delete(endpoint + 'user/' + id, httpOptions);
  }

  updateUser(payload:any): Observable<any> {
    return this.http.put(endpoint + 'user/'+ payload.id, payload, httpOptions);
  }

  getUserById(id:any): Observable<any> {
    return this.http.get(endpoint + 'user/' +  id, httpOptions);
  }

  getUserByEmail(id:any): Observable<any> {
    return this.http.get(endpoint + 'user/email/' +  id, httpOptions);
  }

  getUserPaged(filter:any = {}, offset:number = 0, limit:number = 10, orderBy:string = 'Description', sort:number = 0): Observable<any> {
    let query = this.queryStringFactory.create(filter);

    return this.http.get<any>(endpoint + `user?offset=${offset}&limit=${limit}&orderBy=${orderBy}&sortDirection=${sort}${query}`, httpOptions);
  }
}
