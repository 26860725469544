import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';
import { AuthService } from './auth.service';
import { TokenStorageService } from 'src/app/shared/token-storage/token-storage.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { CnhiMenuMapperService } from 'src/app/shared/services/menu-mapper/cnhi-menu-mapper.service';
import { BrandConfigurationResolver } from 'src/app/shared/brands-configuration/brand-configuration-resolver';
import { MsalAuthWrapper } from 'src/app/shared/wrappers/msal-auth-wrapper';
import { MsalService } from '@azure/msal-angular';
//import { LanguageSite } from 'src/app/shared/language-site/language-site';
import { TranslateService } from '@ngx-translate/core';
//import { OwlOptions } from 'ngx-owl-carousel-o';

const STORAGE_KEY_REMEMBER_ACCESS = "CNHI-REMEMBER-ACCESS";
const STORAGE_KEY_REMEMBER_LOGIN = "CNHI-REMEMBER-LOGIN";
const STORAGE_KEY_REMEMBER_PASSWORD = "CNHI-REMEMBER-PASSWORD";

@Component({
  selector: 'cnhi-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public brazil: string = './assets/img/countries/brazil.png';
  public argentina: string = './assets/img/countries/argentina.png';
  public eua: string = './assets/img/countries/united-states.png';
  public italy: string = './assets/img/countries/italy.png';
  public logoSlide: string = './../../../assets/img/cnhi/slides/1.jpg';

  toast = Swal.mixin({
    width: 400,
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    showCloseButton: true,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  });

  form: any = {
    user: null,
    password: null
  };

  errorMessage = '';
  roles: string[] = [];
  public id: string = '';
  public logoMarca: string = this.brandConfiguration.getLogo();
  public hidePassword: boolean = true;
  public rememberAccess: boolean = false;
  public progressBar = false;
  public enableButton = false;

  constructor(
    private router: Router,
    private authService: AuthService,
    private tokenStorage: TokenStorageService,
    private localStorageService: LocalStorageService,
    private brandConfiguration: BrandConfigurationResolver,
    private cnhiMenuMapperService: CnhiMenuMapperService,
    private msalAuthWrapper: MsalAuthWrapper,
    private msalService: MsalService,
//    private languageSite: LanguageSite,
    private translate:TranslateService
  ) {
    this.setInitalLanguage();
  }

  ngOnInit(): void {
    if (this.localStorageService.retrieve(STORAGE_KEY_REMEMBER_ACCESS)) {
      this.rememberAccess = this.localStorageService.retrieve(STORAGE_KEY_REMEMBER_ACCESS);
    }
  }

  disabledKeyLogin(e: any) {
    e.target.disabled = true;
  }

  submit(): void {
    this.toogleProgressBar(true);
    this.toogleBtnSubmit(true);
    this.loginWithMsal();
  }

  private loginWithAuthApi() {
    const { user, password } = this.form;
    this.authService.login(user, password).subscribe(
      data => {
        this.tokenStorage.saveToken(data.response.token);
        this.tokenStorage.saveMenu(JSON.stringify(this.cnhiMenuMapperService.convertMenuFromApi(data.response.jMenu)));
        this.showSuccess();
        this.redirectTo();
      },
      err => {
        this.toogleBtnSubmit(false);
        this.toogleProgressBar(false);
        this.errorMessage = err.message;
        this.showError();
      }
    );
  }

  private loginWithMsal() {
    this.msalAuthWrapper.login(() => {
      // this.tokenStorage.saveToken('eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyRGF0YSI6IntcIlVzZXJcIjpcIkY4Njg2MkRcIixcIkRvbWFpblwiOlwiQ05IMVwiLFwiTmFtZVwiOlwiTWF0ZXVzIFNhbnRvcyBMZW1vc1wiLFwiQ3VsdHVyZUNvZGVcIjpcInB0LUJSXCIsXCJDdWx0dXJlTmFtZVwiOlwiUG9ydHVndWVzIChCcmFzaWwpXCIsXCJBY2Nlc3NDb250cm9sUGVybWlzc2lvblwiOltdLFwiUHJvZmlsZXNcIjpbXCJBRE1cIixcIkFQMVwiLFwiQVAyXCIsXCJBUDNcIixcIkFQNFwiLFwiQVBDXCIsXCJBUFJcIixcIlBMR1wiLFwiUExOXCIsXCJSRVFcIl0sXCJJc01hc3RlclwiOmZhbHNlLFwiSXNBZG1pbmlzdHJhdG9yXCI6ZmFsc2UsXCJJc01hbmFnZXJcIjpmYWxzZSxcIklzVGVhbUxlYWRlclwiOmZhbHNlfSIsIm5iZiI6MTY1NzI5Mjc3MSwiZXhwIjoxNjU5MDIwNzcxLCJpYXQiOjE2NTcyOTI3NzEsImlzcyI6Imh0dHBzOi8vbG9jYWxob3N0IiwiYXVkIjoiaHR0cHM6Ly9sb2NhbGhvc3QifQ.oHZaU9V5yWUEPQ3f-dUeiDl3twUZQ7GT14j0bwhuCFw');
      // this.showSuccess();
      // this.redirectTo();
      this.getMenu();
    });
  }

  private getMenu(){
    const account = this.msalService.instance.getActiveAccount();

    localStorage.setItem('username', account.username);

    this.authService.postAccess({ name: account?.name, userID: account?.username, tokenMsal: '' }).subscribe(res => {
      this.tokenStorage.saveToken(res.token);
      this.tokenStorage.saveMenu(JSON.stringify(this.cnhiMenuMapperService.convertMenuFromApi(res.jMenu)));
      this.showSuccess();
      this.redirectTo();
    });
  }

  toogleProgressBar(status: any): void {
    this.progressBar = status;
  }

  toogleBtnSubmit(status: any): void {
    this.enableButton = status;
  }

  onChangeRememberAccess(): void {
    const { user, password } = this.form;
    if (this.rememberAccess) {
      this.localStorageService.store(STORAGE_KEY_REMEMBER_ACCESS, true)
      this.localStorageService.store(STORAGE_KEY_REMEMBER_LOGIN, user)
      this.localStorageService.store(STORAGE_KEY_REMEMBER_PASSWORD, password)
    } else {
      this.localStorageService.clear(STORAGE_KEY_REMEMBER_ACCESS)
      this.localStorageService.clear(STORAGE_KEY_REMEMBER_LOGIN)
      this.localStorageService.clear(STORAGE_KEY_REMEMBER_PASSWORD)
    }
  }

  setInitalLanguage(): void {
    const chosenLanguage = localStorage.getItem('chosenLanguageCase');

    if (chosenLanguage){
      this.translate.use(chosenLanguage);
    } else {
      const browserLanguage = this.translate.getBrowserLang();
      this.changeSiteLanguage(browserLanguage);
    }
  }

  changeSiteLanguage(localeCode: any): void {
    this.translate.use(localeCode);
    localStorage.setItem('chosenLanguageCase', localeCode);
    this.reloadWindow();
  }

  reloadWindow() {
    const currentPage = this.router.url;
    this.router.navigateByUrl(`/`).then(
            () => {this.router.navigateByUrl(currentPage);});
}

  redirectTo(): void {
    this.router.navigate(['home']);
  }

  showSuccess() {
    this.toast.fire({
      icon: 'success',
      title: this.translate.instant('@CNHI-LOGIN-SUCCESS')
    })
  }

  showError() {
    this.toast.fire({
      icon: 'error',
      title: this.translate.instant('@CNHI-LOGIN-ERROR')
    })
  }

  // setLanguage(language: string) {
  //   this.languageSite.setCurrentLanguage(language);
  // }

  // customOptions: OwlOptions = {
  //   loop: true,
  //   autoplay: true,
  //   center: true,
  //   dots: false,
  //   autoHeight: false,
  //   autoWidth: true,
  //   responsive: {
  //     0: {
  //       items: 1,
  //     },
  //     600: {
  //       items: 1,
  //     },
  //     1000: {
  //       items: 1,
  //     }
  //   }
  // }

  // slides = this.brandConfiguration.getImagesToSlide();
}
