import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { Component, Inject, LOCALE_ID, Renderer2 } from '@angular/core';
import { ConfigService } from '../@vex/config/config.service';
import { Settings } from 'luxon';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { NavigationService } from '../@vex/services/navigation.service';
import { TranslateService } from '@ngx-translate/core';
import { LayoutService } from '../@vex/services/layout.service';
import { ActivatedRoute } from '@angular/router';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { SplashScreenService } from '../@vex/services/splash-screen.service';
import { VexConfigName } from '../@vex/config/config-name.model';
import { ColorSchemeName } from '../@vex/config/colorSchemeName';
import { MatIconRegistry, SafeResourceUrlWithIconOptions } from '@angular/material/icon';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ColorVariable, colorVariables } from '../@vex/components/config-panel/color-variables';

@Component({
  selector: 'vex-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent{

  constructor(private configService: ConfigService,
              private renderer: Renderer2,
              private platform: Platform,
              @Inject(DOCUMENT) private document: Document,
              @Inject(LOCALE_ID) private localeId: string,
              private _adapter: DateAdapter<any>,
              @Inject(MAT_DATE_LOCALE) private _locale: string,
              private layoutService: LayoutService,
              private route: ActivatedRoute,
              private navigationService: NavigationService,
              private translate: TranslateService,
              private splashScreenService: SplashScreenService,
              private readonly matIconRegistry: MatIconRegistry,
              private readonly domSanitizer: DomSanitizer
      ) {
    Settings.defaultLocale = this.localeId;

    this._locale = this.localeId;
    this._adapter.setLocale(this._locale);

    const chosenLanguage = localStorage.getItem('chosenLanguageCase');
    this.translate.use(chosenLanguage);

    if (this.platform.BLINK) {
      this.renderer.addClass(this.document.body, 'is-blink');
    }

    this.matIconRegistry.addSvgIconResolver(
      (
        name: string,
        namespace: string
      ): SafeResourceUrl | SafeResourceUrlWithIconOptions | null => {
        switch (namespace) {
          case 'mat':
            return this.domSanitizer.bypassSecurityTrustResourceUrl(
              `assets/img/icons/material-design-icons/two-tone/${name}.svg`
            );

          case 'logo':
            return this.domSanitizer.bypassSecurityTrustResourceUrl(
              `assets/img/icons/logos/${name}.svg`
            );

          case 'flag':
            return this.domSanitizer.bypassSecurityTrustResourceUrl(
              `assets/img/icons/flags/${name}.svg`
            );
        }
      }
    );



    /**
     * Customize the template to your needs with the ConfigService
     * Example:
     *  this.configService.updateConfig({
     *    sidenav: {
     *      title: 'Custom App',
     *      imageUrl: '//placehold.it/100x100',
     *      showCollapsePin: false
     *    },
     *    footer: {
     *      visible: false
     *    }
     *  });
     */

    /**
     * Config Related Subscriptions
     * You can remove this if you don't need the functionality of being able to enable specific configs with queryParams
     * Example: example.com/?layout=apollo&style=default
     */
    this.route.queryParamMap.subscribe(queryParamMap => {
      if (queryParamMap.has('layout')) {
        this.configService.setConfig(queryParamMap.get('layout') as VexConfigName);
      }

      if (queryParamMap.has('style')) {
        this.configService.updateConfig({
          style: {
            colorScheme: queryParamMap.get('style') as ColorSchemeName
          }
        });
      }

      if (queryParamMap.has('primaryColor')) {
        const color: ColorVariable = colorVariables[queryParamMap.get('primaryColor')];

        if (color) {
          this.configService.updateConfig({
            style: {
              colors: {
                primary: color
              }
            }
          });
        }
      }

      if (queryParamMap.has('rtl')) {
        this.configService.updateConfig({
          direction: coerceBooleanProperty(queryParamMap.get('rtl')) ? 'rtl' : 'ltr'
        });
      }
    });

    /**
    * Add your own routes here
    */

//    this.setMenuItens();
  }

  setMenuItens(){
  //   this.translate.get('@CNHI-MENU-HOME').subscribe((home: string) => {
  //     this.navigationService.items = [
  //       {
  //         type: 'link',
  //         label: home,
  //         icon: 'mat:home',
  //         route: '/home',
  //         routerLinkActiveOptions: { exact: true }
  //       },
  //       {
  //         type: 'link',
  //         icon: 'mat:privacy_tip',
  //         label: this.translate.instant('@CNHI-MENU-AUDIT'),
  //         route: '/utilities/apps/audit',
  //         routerLinkActiveOptions: { exact: true }
  //       },
  //       {
  //         type: 'dropdown',
  //         icon: 'mat:dashboard',
  //         label: this.translate.instant('@CNHI-MENU-DASHBOARD'),
  //         children: [
  //           {
  //             type: 'link',
  //             label: this.translate.instant('@CNHI-MENU-DASHBOARD-EXPORT'),
  //             route: '/utilities/apps/export-data',
  //             routerLinkActiveOptions: { exact: true }
  //           }
  //         ]
  //       },
  //       {
  //         type: 'subheading',
  //         label: 'App',
  //         children: [
  //           {
  //             type: 'dropdown',
  //             label: this.translate.instant('@CNHI-MENU-REGISTRATIONS'),
  //             icon: 'mat:app_registration',
  //             children: [
  //               {
  //                 type: 'link',
  //                 label: this.translate.instant('@CNHI-MENU-ACCOUNT'),
  //                 route: '/utilities/apps/account',
  //                 routerLinkActiveOptions: { exact: true }
  //               },
  //               {
  //                 type: 'link',
  //                 label: this.translate.instant('@CNHI-MENU-COMPANY'),
  //                 route: '/utilities/apps/company',
  //                 routerLinkActiveOptions: { exact: true }
  //               },
  //               {
  //                 type: 'link',
  //                 label: this.translate.instant('@CNHI-MENU-CONTRACT'),
  //                 route: '/utilities/apps/contract',
  //                 routerLinkActiveOptions: { exact: true }
  //               },
  //               {
  //                 type: 'link',
  //                 label: this.translate.instant('@CNHI-MENU-COSTCENTER'),
  //                 route: '/utilities/apps/cost-center',
  //                 routerLinkActiveOptions: { exact: true }
  //               },
  //               {
  //                 type: 'link',
  //                 label: this.translate.instant('@CNHI-MENU-INVOICE'),
  //                 route: '/utilities/apps/invoice',
  //                 routerLinkActiveOptions: { exact: true }
  //               },
  //               {
  //                 type: 'link',
  //                 label: this.translate.instant('@CNHI-MENU-PROVIDER'),
  //                 route: '/utilities/apps/provider',
  //                 routerLinkActiveOptions: { exact: true }
  //               },
  //               {
  //                 type: 'link',
  //                 label: this.translate.instant('@CNHI-MENU-SERVICE'),
  //                 route: '/utilities/apps/service',
  //                 routerLinkActiveOptions: { exact: true }
  //               },
  //               {
  //                 type: 'link',
  //                 label: this.translate.instant('@CNHI-MENU-USER'),
  //                 route: '/utilities/apps/user',
  //                 routerLinkActiveOptions: { exact: true }
  //               }
  //             ]
  //           },
  //         ]
  //       },
  //       {
  //         type: 'link',
  //         label: 'Upload',
  //         icon: 'mat:upload',
  //         route: '/utilities/apps/upload',
  //         routerLinkActiveOptions: { exact: true }
  //       },

  //       // {
  //       //   type: 'subheading',
  //       //   label: 'Security',
  //       //   children: [
  //       //     {
  //       //       type: 'dropdown',
  //       //       label: 'Authentication',
  //       //       icon: 'mat:lock',
  //       //       children: [
  //       //         {
  //       //           type: 'link',
  //       //           label: 'Login',
  //       //           route: '/'
  //       //         }
  //       //       ]
  //       //     },
  //       //   ]
  //       // },
  //     ];
  // });
  }

}
