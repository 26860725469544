import { Component, Input, OnInit } from '@angular/core';
import { NavigationService } from '../../services/navigation.service';
import { LayoutService } from '../../services/layout.service';
import { ConfigService } from '../../config/config.service';
import { filter, map, startWith, switchMap } from 'rxjs/operators';
import { NavigationItem, NavigationLink } from '../../interfaces/navigation-item.interface';
import { PopoverService } from '../../components/popover/popover.service';
import { Observable, of } from 'rxjs';
import { UserMenuComponent } from '../../components/user-menu/user-menu.component';
import { MatDialog } from '@angular/material/dialog';
import { SearchModalComponent } from '../../components/search-modal/search-modal.component';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from './../../../app/services/user.service';

@Component({
  selector: 'vex-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {

  @Input() collapsed: boolean;
  collapsedOpen$ = this.layoutService.sidenavCollapsedOpen$;
  title$ = this.configService.config$.pipe(map(config => config.sidenav.title));
  imageUrl$ = this.configService.config$.pipe(map(config => config.sidenav.imageUrl));
  showCollapsePin$ = this.configService.config$.pipe(map(config => config.sidenav.showCollapsePin));
  userVisible$ = this.configService.config$.pipe(map(config => config.sidenav.user.visible));
  searchVisible$ = this.configService.config$.pipe(map(config => config.sidenav.search.visible));

  userMenuOpen$: Observable<boolean> = of(false);

  items = this.navigationService.items;

  userProfile: string;
  menuAdministrator: any[] = [
    {
      type: 'link',
      label: this.translate.instant('@CNHI-MENU-HOME'),
      icon: 'mat:home',
      route: '/home',
      routerLinkActiveOptions: { exact: true }
    },
    {
      type: 'subheading',
      label: 'App',
      children: [
        {
          type: 'link',
          icon: 'mat:privacy_tip',
          label: this.translate.instant('@CNHI-MENU-AUDIT'),
          route: '/utilities/apps/audit',
          routerLinkActiveOptions: { exact: true }
        },
        {
          type: 'dropdown',
          icon: 'mat:dashboard',
          label: this.translate.instant('@CNHI-MENU-DASHBOARD'),
          children: [
            {
              type: 'link',
              label: this.translate.instant('@CNHI-MENU-DASHBOARD-EXPORT'),
              route: '/utilities/apps/export-data',
              routerLinkActiveOptions: { exact: true }
            }
          ]
        },
        {
          type: 'dropdown',
          label: this.translate.instant('@CNHI-MENU-REGISTRATIONS'),
          icon: 'mat:post_add',
          children: [
            {
              type: 'link',
              label: this.translate.instant('@CNHI-MENU-REGISTRATIONS-ACCOUNT'),
              route: '/utilities/apps/account',
              routerLinkActiveOptions: { exact: true }
            },
            {
              type: 'link',
              label: this.translate.instant('@CNHI-MENU-REGISTRATIONS-COMPANY'),
              route: '/utilities/apps/company',
              routerLinkActiveOptions: { exact: true }
            },
            {
              type: 'link',
              label: this.translate.instant('@CNHI-MENU-REGISTRATIONS-CONTRACT'),
              route: '/utilities/apps/contract',
              routerLinkActiveOptions: { exact: true }
            },
            {
              type: 'link',
              label: this.translate.instant('@CNHI-MENU-REGISTRATIONS-COSTCENTER'),
              route: '/utilities/apps/cost-center',
              routerLinkActiveOptions: { exact: true }
            },
            {
              type: 'link',
              label: this.translate.instant('@CNHI-MENU-REGISTRATIONS-INVOICE'),
              route: '/utilities/apps/invoice',
              routerLinkActiveOptions: { exact: true }
            },
            {
              type: 'link',
              label: this.translate.instant('@CNHI-MENU-REGISTRATIONS-PROVIDER'),
              route: '/utilities/apps/provider',
              routerLinkActiveOptions: { exact: true }
            },
            {
              type: 'link',
              label: this.translate.instant('@CNHI-MENU-REGISTRATIONS-SERVICE'),
              route: '/utilities/apps/service',
              routerLinkActiveOptions: { exact: true }
            },
            {
              type: 'link',
              label: this.translate.instant('@CNHI-MENU-REGISTRATIONS-USER'),
              route: '/utilities/apps/user',
              routerLinkActiveOptions: { exact: true }
            }
          ]
        },
      ]
    },
    {
      type: 'link',
      label: this.translate.instant('@CNHI-MENU-UPLOAD'),
      icon: 'mat:upload',
      route: '/utilities/apps/upload',
      routerLinkActiveOptions: { exact: true }
    },
  ];

  menuAnalyst: any[] = [
    {
      type: 'link',
      label: this.translate.instant('@CNHI-MENU-HOME'),
      icon: 'mat:home',
      route: '/home',
      routerLinkActiveOptions: { exact: true }
    },
    {
      type: 'subheading',
      label: 'App',
      children: [
        {
          type: 'link',
          icon: 'mat:privacy_tip',
          label: this.translate.instant('@CNHI-MENU-AUDIT'),
          route: '/utilities/apps/audit',
          routerLinkActiveOptions: { exact: true }
        },
        {
          type: 'dropdown',
          icon: 'mat:dashboard',
          label: this.translate.instant('@CNHI-MENU-DASHBOARD'),
          children: [
            {
              type: 'link',
              label: this.translate.instant('@CNHI-MENU-DASHBOARD-EXPORT'),
              route: '/utilities/apps/export-data',
              routerLinkActiveOptions: { exact: true }
            }
          ]
        },
        {
          type: 'dropdown',
          label: this.translate.instant('@CNHI-MENU-REGISTRATIONS'),
          icon: 'mat:post_add',
          children: [
            {
              type: 'link',
              label: this.translate.instant('@CNHI-MENU-REGISTRATIONS-ACCOUNT'),
              route: '/utilities/apps/account',
              routerLinkActiveOptions: { exact: true }
            },
            {
              type: 'link',
              label: this.translate.instant('@CNHI-MENU-REGISTRATIONS-COMPANY'),
              route: '/utilities/apps/company',
              routerLinkActiveOptions: { exact: true }
            },
            {
              type: 'link',
              label: this.translate.instant('@CNHI-MENU-REGISTRATIONS-CONTRACT'),
              route: '/utilities/apps/contract',
              routerLinkActiveOptions: { exact: true }
            },
            {
              type: 'link',
              label: this.translate.instant('@CNHI-MENU-REGISTRATIONS-COSTCENTER'),
              route: '/utilities/apps/cost-center',
              routerLinkActiveOptions: { exact: true }
            },
            {
              type: 'link',
              label: this.translate.instant('@CNHI-MENU-REGISTRATIONS-INVOICE'),
              route: '/utilities/apps/invoice',
              routerLinkActiveOptions: { exact: true }
            },
            {
              type: 'link',
              label: this.translate.instant('@CNHI-MENU-REGISTRATIONS-PROVIDER'),
              route: '/utilities/apps/provider',
              routerLinkActiveOptions: { exact: true }
            },
            {
              type: 'link',
              label: this.translate.instant('@CNHI-MENU-REGISTRATIONS-SERVICE'),
              route: '/utilities/apps/service',
              routerLinkActiveOptions: { exact: true }
            },
          ]
        },
      ]
    },
    {
      type: 'link',
      label: this.translate.instant('@CNHI-MENU-UPLOAD'),
      icon: 'mat:upload',
      route: '/utilities/apps/upload',
      routerLinkActiveOptions: { exact: true }
    },
  ];

  constructor(private navigationService: NavigationService,
              private layoutService: LayoutService,
              private configService: ConfigService,
              private readonly popoverService: PopoverService,
              private readonly dialog: MatDialog,
              private translate: TranslateService,
              private userService: UserService) { }

  ngOnInit() {
   this.menuAuthorize();
 //  this.setMenuItens();
//   this.setInitalLanguage();
  }

  menuAuthorize(): void {
    const username = localStorage.getItem('username');

    this.userService.getUserByEmail(username).subscribe({
      next: (res) => {
        this.userProfile = res.nameING;
      },
      error: (error) => {
      },
      complete: () => {
        this.setInitalLanguage();
        this.setMenuItens();
      }
    });
  }

  setMenuItens(): void {
   this.translate.use(localStorage.getItem('chosenLanguageCase'));
   this.translate.get('@CNHI-MENU-HOME').subscribe((home: string) => {
     this.translate.use(localStorage.getItem('chosenLanguageCase'));

      // if (this.userProfile === 'Administrator') this.navigationService.items = this.menuAdministrator;
      // if (this.userProfile === 'Analyst')       this.navigationService.items = this.menuAnalyst;

      if (this.userProfile === 'Administrator') {

      this.navigationService.items =
      [
        {
          type: 'link',
          label: this.translate.instant('@CNHI-MENU-HOME'),
          icon: 'mat:home',
          route: '/home',
          routerLinkActiveOptions: { exact: true }
        },
        {
          type: 'subheading',
          label: 'App',
          children: [
            {
              type: 'link',
              icon: 'mat:privacy_tip',
              label: this.translate.instant('@CNHI-MENU-AUDIT'),
              route: '/utilities/apps/audit',
              routerLinkActiveOptions: { exact: true }
            },
            {
              type: 'dropdown',
              icon: 'mat:dashboard',
              label: this.translate.instant('@CNHI-MENU-DASHBOARD'),
              children: [
                {
                  type: 'link',
                  label: this.translate.instant('@CNHI-MENU-DASHBOARD-EXPORT'),
                  route: '/utilities/apps/export-data',
                  routerLinkActiveOptions: { exact: true }
                }
              ]
            },
            {
              type: 'dropdown',
              label: this.translate.instant('@CNHI-MENU-REGISTRATIONS'),
              icon: 'mat:post_add',
              children: [
                {
                  type: 'link',
                  label: this.translate.instant('@CNHI-MENU-REGISTRATIONS-ACCOUNT'),
                  route: '/utilities/apps/account',
                  routerLinkActiveOptions: { exact: true }
                },
                {
                  type: 'link',
                  label: this.translate.instant('@CNHI-MENU-REGISTRATIONS-COMPANY'),
                  route: '/utilities/apps/company',
                  routerLinkActiveOptions: { exact: true }
                },
                {
                  type: 'link',
                  label: this.translate.instant('@CNHI-MENU-REGISTRATIONS-CONTRACT'),
                  route: '/utilities/apps/contract',
                  routerLinkActiveOptions: { exact: true }
                },
                {
                  type: 'link',
                  label: this.translate.instant('@CNHI-MENU-REGISTRATIONS-COSTCENTER'),
                  route: '/utilities/apps/cost-center',
                  routerLinkActiveOptions: { exact: true }
                },
                {
                  type: 'link',
                  label: this.translate.instant('@CNHI-MENU-REGISTRATIONS-INVOICE'),
                  route: '/utilities/apps/invoice',
                  routerLinkActiveOptions: { exact: true }
                },
                {
                  type: 'link',
                  label: this.translate.instant('@CNHI-MENU-REGISTRATIONS-PROVIDER'),
                  route: '/utilities/apps/provider',
                  routerLinkActiveOptions: { exact: true }
                },
                {
                  type: 'link',
                  label: this.translate.instant('@CNHI-MENU-REGISTRATIONS-SERVICE'),
                  route: '/utilities/apps/service',
                  routerLinkActiveOptions: { exact: true }
                },
                {
                  type: 'link',
                  label: this.translate.instant('@CNHI-MENU-REGISTRATIONS-USER'),
                  route: '/utilities/apps/user',
                  routerLinkActiveOptions: { exact: true }
                }
              ]
            },
          ]
        },
        {
          type: 'link',
          label: this.translate.instant('@CNHI-MENU-UPLOAD'),
          icon: 'mat:upload',
          route: '/utilities/apps/upload',
          routerLinkActiveOptions: { exact: true }
        },
      ];
    } else {
      this.navigationService.items =
      [
        {
          type: 'link',
          label: this.translate.instant('@CNHI-MENU-HOME'),
          icon: 'mat:home',
          route: '/home',
          routerLinkActiveOptions: { exact: true }
        },
        {
          type: 'subheading',
          label: 'App',
          children: [
            {
              type: 'link',
              icon: 'mat:privacy_tip',
              label: this.translate.instant('@CNHI-MENU-AUDIT'),
              route: '/utilities/apps/audit',
              routerLinkActiveOptions: { exact: true }
            },
            {
              type: 'dropdown',
              icon: 'mat:dashboard',
              label: this.translate.instant('@CNHI-MENU-DASHBOARD'),
              children: [
                {
                  type: 'link',
                  label: this.translate.instant('@CNHI-MENU-DASHBOARD-EXPORT'),
                  route: '/utilities/apps/export-data',
                  routerLinkActiveOptions: { exact: true }
                }
              ]
            },
            {
              type: 'dropdown',
              label: this.translate.instant('@CNHI-MENU-REGISTRATIONS'),
              icon: 'mat:post_add',
              children: [
                {
                  type: 'link',
                  label: this.translate.instant('@CNHI-MENU-REGISTRATIONS-ACCOUNT'),
                  route: '/utilities/apps/account',
                  routerLinkActiveOptions: { exact: true }
                },
                {
                  type: 'link',
                  label: this.translate.instant('@CNHI-MENU-REGISTRATIONS-COMPANY'),
                  route: '/utilities/apps/company',
                  routerLinkActiveOptions: { exact: true }
                },
                {
                  type: 'link',
                  label: this.translate.instant('@CNHI-MENU-REGISTRATIONS-CONTRACT'),
                  route: '/utilities/apps/contract',
                  routerLinkActiveOptions: { exact: true }
                },
                {
                  type: 'link',
                  label: this.translate.instant('@CNHI-MENU-REGISTRATIONS-COSTCENTER'),
                  route: '/utilities/apps/cost-center',
                  routerLinkActiveOptions: { exact: true }
                },
                {
                  type: 'link',
                  label: this.translate.instant('@CNHI-MENU-REGISTRATIONS-INVOICE'),
                  route: '/utilities/apps/invoice',
                  routerLinkActiveOptions: { exact: true }
                },
                {
                  type: 'link',
                  label: this.translate.instant('@CNHI-MENU-REGISTRATIONS-PROVIDER'),
                  route: '/utilities/apps/provider',
                  routerLinkActiveOptions: { exact: true }
                },
                {
                  type: 'link',
                  label: this.translate.instant('@CNHI-MENU-REGISTRATIONS-SERVICE'),
                  route: '/utilities/apps/service',
                  routerLinkActiveOptions: { exact: true }
                },
              ]
            },
          ]
        },
        {
          type: 'link',
          label: this.translate.instant('@CNHI-MENU-UPLOAD'),
          icon: 'mat:upload',
          route: '/utilities/apps/upload',
          routerLinkActiveOptions: { exact: true }
        },
      ];
    }
    this.items = this.navigationService.items;
   });
  }

  setInitalLanguage(): void {
    const chosenLanguage = localStorage.getItem('chosenLanguageCase');

    if (chosenLanguage){
      this.translate.use(chosenLanguage);
    } else {
      const browserLanguage = this.translate.getBrowserLang();
      this.changeSiteLanguage(browserLanguage);
    }
  }

  changeSiteLanguage(localeCode: any): void {
    this.translate.use(localeCode);
    localStorage.setItem('chosenLanguageCase', localeCode);
  }

  collapseOpenSidenav() {
    this.layoutService.collapseOpenSidenav();
  }

  collapseCloseSidenav() {
    this.layoutService.collapseCloseSidenav();
  }

  toggleCollapse() {
    this.collapsed ? this.layoutService.expandSidenav() : this.layoutService.collapseSidenav();
  }

  trackByRoute(index: number, item: NavigationLink): string {
    return item.route;
  }

  openProfileMenu(origin: HTMLDivElement): void {
    this.userMenuOpen$ = of(
      this.popoverService.open({
        content: UserMenuComponent,
        origin,
        offsetY: -8,
        width: origin.clientWidth,
        position: [
          {
            originX: 'center',
            originY: 'top',
            overlayX: 'center',
            overlayY: 'bottom'
          }
        ]
      })
    ).pipe(
      switchMap(popoverRef => popoverRef.afterClosed$.pipe(map(() => false))),
      startWith(true),
    );
  }

  openSearch(): void {
    this.dialog.open(SearchModalComponent, {
      panelClass: 'vex-dialog-glossy',
      width: '100%',
      maxWidth: '600px'
    });
  }
}

