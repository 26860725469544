import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class CnhiMenuMapperService {

  constructor(
  ) { }

  public convertMenuFromApi(response: any) {
    let menus = [];

    for(var i=0, l=response.length;i<l;i++){
      let childs = [];
      if(response[i].child){
//        console.log('Achou valor na lista de childs');
        childs = (<any[]>response[i].child).map(child => {
          let obj: any = {
            label: child.nombrePt,
            path: child.formulario
          };

          Object.keys(child).filter(e => {
            if(e.indexOf('nombre') > -1){
              obj[e] = child[e];
            }
            return true;
          })

          return  obj;
        })
      }

      let menu: any = {
        label: response[i].nombrePt,
        path: response[i].formulario,
        childs: childs
      }

      Object.keys(response[i]).filter(e => {
        if (e.indexOf('nombre') > -1) {
          menu[e] = response[i][e];
        }
        return true;
      })

      menus.push(menu)
    }

//    console.log('MENU: ', menus);
    return menus
  }

  public changeMenuLanguage(lang: any, menu: any[]) {
    return menu.map(e => {
      e.label = e['nombre' + (lang[0].toUpperCase() + lang.substring(1))] || e.label;
      if(e.childs && e.childs.length > 0){
        e.childs = (<any[]>e.childs).map(x => {
          x.label = x['nombre' + (lang[0].toUpperCase() + lang.substring(1))] || x.label;
          return x
        });
      }
      return e
    })
  }
}
