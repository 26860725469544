import { Injectable, OnInit } from "@angular/core";
import { AbstractControl } from "@angular/forms";
import { Observable } from "rxjs";
import {map, startWith} from 'rxjs/operators';

@Injectable()
export class AutocompleteWrapper implements OnInit {
  ngOnInit(): void {}

  applyAutocomplete(arr: any[], control: AbstractControl) : Observable<any[]>{
    let filtered = control.valueChanges.pipe(
      startWith(''),
      map(value => {
        const name = typeof value === 'string' ? value : value?.name;
        return name ? this._filter(name as string, arr) : arr.slice();
      }),
    );
    return filtered;
  }

  private _filter(value: string, arr: any[]): string[] {
    const filterValue = value.toLowerCase();

    return arr.filter(option => option.name.toLowerCase().includes(filterValue));
  }
}
