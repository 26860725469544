import { IBrandConfiguration } from "./interface-brand-configuration";

export class IvecoConfiguration implements IBrandConfiguration {
  constructor(){}

  getLogo(): string {
    return './assets/img/iveco/logo/iveco.png';
  }

  getImagesToSlide():any[]{
    return [
      { id: 1, img: './assets/img/iveco/slides/1.jpg' },
      { id: 2, img: './assets/img/iveco/slides/2.jpg' },
      { id: 3, img: './assets/img/iveco/slides/3.jpeg' }
    ];
  }
}