<!-- <div class="flex-container" style="background-image:url('./../../../assets/img/cnhi/slides/1.jpg');">
  <div class="flex-login">
    <div class="white">
      <div align="center">
        <img [src]='logoMarca'>
      </div>
      <div>
        <mat-progress-bar class="mb-1"
          [ngStyle]="{'display':progressBar?'block':'none'}" mode="indeterminate">
        </mat-progress-bar>
      </div>
      <mat-dialog-actions align="center">
        <div class="button-login">
          <button type="button" mat-raised-button color="primary"
            (click)="submit()" [disabled]="enableButton">
            <!-- {{'@CNHI-LOGIN' | translate }} 
            ENTER
          </button>
        </div>
      </mat-dialog-actions>

      
    </div>
  </div> -->

  <!-- <div class="flex-carousel">
    <div class="white">
      <div align="center">
        <img [src]='logoSlide'>
      </div>
    </div>
  </div>
</div> -->
<div class="login-cnh">

<div class="w-full h-full flex flex-col items-center justify-center">
  <div class="card overflow-hidden w-full max-w-xs">
    <div class="p-6 pb-0 flex flex-col items-center justify-center">
      <div class="fill-current text-center">
        <img class="w-16" src="assets/img/demo/logo.svg">
      </div>
    </div>

    <div class="text-center mt-4">
      <h2 class="title m-0">Utilities</h2>
      <h4 class="body-2 text-secondary m-0">Sign in with your CNHi credentials</h4>
    </div>

    <div class="p-6 flex flex-col gap-4">
      <button (click)="submit()" color="primary" mat-raised-button type="button">
        SIGN IN
      </button>
      <div class="button-flag" align="center">
        <button mat-icon-button
          (click)="changeSiteLanguage('en')">
          <mat-icon svgIcon="flag:en"></mat-icon>
        </button>
        <button mat-icon-button
          (click)="changeSiteLanguage('es')">
          <mat-icon svgIcon="flag:es"></mat-icon>
        </button>
        <button mat-icon-button
          (click)="changeSiteLanguage('it')">
          <mat-icon svgIcon="flag:it"></mat-icon>
        </button>
        <button mat-icon-button
          (click)="changeSiteLanguage('pt')">
          <mat-icon svgIcon="flag:pt"></mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>
</div>

<!--
<div class="container-fluid h-100">
    <div class="row h-100">
      <div class="col-xl-2 mt-xs-1 pl-xl-4 align-middle align-self-center">
        <div class="text-center text-xl-left">
          <div class="col-7 col-xl-12 pl-xl-0 mx-auto mx-xl-0">
            <img class="img-fluid" [src]='logoMarca'>
          </div>
          <div class="mt-4">
            <div>
              <h1 class="mat-headline" [innerHTML]="'CNHi Utilities'"></h1>
            </div>
          </div>
        </div>
        <form class="mt-4" name="form" role="form" #f="ngForm" novalidate>

          <div class="mt-5" style="background-color: aqua;">
            <mat-progress-bar class="mb-1" [ngStyle]="{'display':progressBar?'block':'none'}" mode="indeterminate">
            </mat-progress-bar>
            <button mat-raised-button class="btn btn-primary w-100" color="primary"
              (click)="submit()" [disabled]="enableButton">{{'@CNHI-LOGIN' | translate }}</button>
          </div>
           <div class="mt-3 d-flex justify-content-between">
            <a type="button" (click)="setLanguage('pt')"><img class="button-image" [src]="brazil"></a>
            <a type="button" class="margin-flag" (click)="setLanguage('es')"><img class="button-image image-border"
                [src]="argentina"></a>
            <a type="button" class="margin-flag" (click)="setLanguage('en')"><img class="button-image" [src]="eua"></a>
            <a type="button" class="margin-flag" (click)="setLanguage('it')"><img class="button-image"
                [src]="italy"></a>
          </div>
        </form>
      </div>
      <div class="col-xl-10 d-none d-xl-block pl-5 pr-0">
         <owl-carousel-o [options]="customOptions">
          <ng-container *ngFor="let slide of slides">
            <ng-template carouselSlide>
              <div class="container-imagem">
                <img [src]="slide.img">
              </div>
            </ng-template>
          </ng-container>
        </owl-carousel-o>
      </div>
    </div>
  </div> -->
