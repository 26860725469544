// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  brand: 'Utilities',
 endpoints: {
    // loginEndpoint: 'https://localhost:44316/api/v1/',
    // apiEndpoint: 'https://localhost:44316/api/v1/'
     loginEndpoint: 'https://api.utilitiesh.cnhindustrial.com/api/v1/',
    apiEndpoint: 'https://api.utilitiesh.cnhindustrial.com/api/v1/'
  },
  clientId: 'ba06ce1f-fb24-4c48-91ae-11d0af05206f',
  // redirectUri: 'http://localhost:4200/',
  // postLogoutRedirectUri: 'http://localhost:4200/login',
  redirectUri: 'https://utilitiesh.cnhindustrial.com/',
  postLogoutRedirectUri: 'https://utilitiesh.cnhindustrial.com/',
  authority: 'https://login.microsoftonline.com/6faf7bab-26c2-4438-a1b5-b9d9590106b6',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
