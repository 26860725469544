import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from 'ngx-webstorage';


const TOKEN_KEY = 'cnhi-token';
const USER_KEY = 'cnhi-user';
const USER_MENU = 'cnhi-menu';

@Injectable({
  providedIn: 'root'
})
export class TokenStorageService {

  constructor(
    private router: Router,
    private localStorageService: LocalStorageService
  ) { }


  singOut(): void {
    window.sessionStorage.clear();
    this.localStorageService.clear();
    //this.router.navigate(['login']);
  }

  public saveToken(token: string): void {
    window.sessionStorage.removeItem(TOKEN_KEY);
    window.sessionStorage.setItem(TOKEN_KEY, token);
  }

  public getToken(): string | null {
    return window.sessionStorage.getItem(TOKEN_KEY);
  }

  public saveUser(user: any): void {
    window.sessionStorage.removeItem(USER_KEY);
    window.sessionStorage.setItem(USER_KEY, user);
  }
  public saveMenu(menu: any): void {
    window.sessionStorage.removeItem(USER_MENU);
    window.sessionStorage.setItem(USER_MENU, menu);
  }

  public getUser(): any {
    const user = window.sessionStorage.getItem(USER_KEY);
    if(user) {
      return JSON.parse(user);
    }

    return {};
  }

  public getMenu(): any {
    const menu = window.sessionStorage.getItem(USER_MENU);
    if(menu) {
      return JSON.parse(menu);
    }

    return [];
  }
}