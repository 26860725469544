import { Injectable, NgModule } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate,RouterModule, Routes } from '@angular/router';
import { CustomLayoutComponent } from './custom-layout/custom-layout.component';

import { Observable } from 'rxjs';
import { TokenStorageService } from './shared/token-storage/token-storage.service';
import { LoginComponent } from './components/authentication/login.component';

@Injectable()
export class AccessGuard implements CanActivate {
  constructor(private tokenStorage: TokenStorageService) {}
  canActivate(route: ActivatedRouteSnapshot): Observable<boolean>|Promise<boolean>|boolean {
    const requiresLogin = route.data['requiresLogin'] || false;
    if (requiresLogin && !this.tokenStorage.getToken()) {
      return false;
    }
    else {
      return true;
    }
  }
}

const routes: Routes = [
  { path: '',
    component: LoginComponent,
    data: { requiresLogin: false },
    canActivate: [AccessGuard]
  },
  { path: 'home',
    component: CustomLayoutComponent,
    data: { requiresLogin: true },
    canActivate: [AccessGuard]
  },
  {
    path: 'utilities',
    component: CustomLayoutComponent,
    children: [
      {
        path: 'apps',
        children: [
          {
            path: 'audit',
            loadChildren: () => import('./modules/audit.module').then(m => m.AuditModule),
            data: { requiresLogin: true},
            canActivate: [AccessGuard]
          },
          {
            path: 'export-data',
            loadChildren: () => import('./modules/dashboard.module').then(m => m.DashboardModule),
            data: { requiresLogin: true},
            canActivate: [AccessGuard]
          },
          {
            path: 'account',
            loadChildren: () => import('./modules/account.module').then(m => m.AccountModule),
            data: { requiresLogin: true},
            canActivate: [AccessGuard]
          },
          {
            path: 'company',
            loadChildren: () => import('./modules/company.module').then(m => m.CompanyModule),
            data: { requiresLogin: true},
            canActivate: [AccessGuard]
          },
          {
            path: 'contract',
            loadChildren: () => import('./modules/contract.module').then(m => m.ContractModule),
            data: { requiresLogin: true},
            canActivate: [AccessGuard]
          },
          {
            path: 'cost-center',
            loadChildren: () => import('./modules/cost-center.module').then(m => m.CostCenterModule),
            data: { requiresLogin: true},
            canActivate: [AccessGuard]
          },
          {
            path: 'invoice',
            loadChildren: () => import('./modules/invoice.module').then(m => m.InvoiceModule),
            data: { requiresLogin: true},
            canActivate: [AccessGuard]
          },
          {
            path: 'provider',
            loadChildren: () => import('./modules/provider.module').then(m => m.ProviderModule),
            data: { requiresLogin: true},
            canActivate: [AccessGuard]
          },
          {
            path: 'service',
            loadChildren: () => import('./modules/service.module').then(m => m.ServiceModule),
            data: { requiresLogin: true},
            canActivate: [AccessGuard]
          },
          {
            path: 'upload',
            loadChildren: () => import('./modules/upload.module').then(m => m.UploadModule),
            data: { requiresLogin: true},
            canActivate: [AccessGuard]
          },
          {
            path: 'user',
            loadChildren: () => import('./modules/user.module').then(m => m.UserModule),
            data: { requiresLogin: true},
            canActivate: [AccessGuard]
          },
        ]
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled',
    relativeLinkResolution: 'corrected',
    anchorScrolling: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
