import { Injectable } from "@angular/core";

@Injectable()
export class QueryStringFactory{
  constructor(){}

  create(instance: any):string{
    let query = '';
    let properties = Object.keys(instance);

    for(let property of properties){
      if(instance[property]){
        query += `&${property}=${instance[property]}`;
      }
    }
    return query;
  }
}
