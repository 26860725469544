import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { CnhiConfiguration } from "./cnhi-configuration";
import { IBrandConfiguration } from "./interface-brand-configuration";
import { IvecoConfiguration } from "./iveco-configuration";
import { CaseConstructionConfiguration } from "./case-construction-configuration";

const brand = environment.brand;

@Injectable()
export class BrandConfigurationResolver implements IBrandConfiguration {
  private brandConfiguration : IBrandConfiguration;

  constructor(){
    if(brand.toLowerCase() == 'iveco'){
      this.brandConfiguration = new IvecoConfiguration();
    } else if(brand.toLowerCase().replace('-', '') == "caseconstruction"){
      this.brandConfiguration = new CaseConstructionConfiguration();
    } else{
      this.brandConfiguration = new CnhiConfiguration();
    }
  }

  getLogo(): string {
    return this.brandConfiguration.getLogo();
  }

  getImagesToSlide():any[]{
    return this.brandConfiguration.getImagesToSlide();
  }
}