import { IBrandConfiguration } from "./interface-brand-configuration";

export class CaseConstructionConfiguration implements IBrandConfiguration {
  constructor(){}

  getLogo(): string {
    return './assets/img/case-construction/logo/case-construction.png';
  }

  getImagesToSlide():any[]{
    return [
      { id: 1, img: './assets/img/case-construction/slides/caseconstruction1.jpg' },
      { id: 2, img: './assets/img/case-construction/slides/caseconstruction2.png' },
    ];
  }
}