import { IBrandConfiguration } from "./interface-brand-configuration";

export class CnhiConfiguration implements IBrandConfiguration {
  constructor(){}

  getLogo(): string {
    return './assets/img/cnhi/logo/cnhi.png';
  }

  getImagesToSlide():any[]{
    return [
      { id: 1, img: './assets/img/cnhi/slides/1.jpg' },
      { id: 2, img: './assets/img/cnhi/slides/2.png' },
      { id: 3, img: './assets/img/cnhi/slides/3.png' }
    ];
  }
}